@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.log-index-rt {
    @include componentDefaultFonts();

    // fix css revex
    :global(.panel-rt){
        :global(.panel-rt-header){
            :global(.panel-rt-actions){
                button:first-child{
                    line-height: 0;
                }
            }   
        }   
    }

    .log-index-filters{
        display: flex;
        vertical-align: middle;
        align-items: center;
        margin-bottom: 15px;
        flex-wrap: wrap;

        >*{
            margin-right: 10px;
        }

        label{
            line-height: 25px;
        }

        button{
            align-self: flex-end;
            justify-self: right;
            margin-left: auto;
            margin-top: auto;
        }

        :global(.datetime-picker-rt):first-of-type{
            max-width: 95px;
        }

        .filters-input{
            flex: 1;

            &:nth-of-type(2),
            &:nth-of-type(3){
                min-width: 300px;
            }
        }

        .select-log-type{
            min-width: 350px;
        }

        .select-log-location{
            min-width: 200px;
        }

        .select-log-per-page{
            min-width: 165px;
        }

    }

    .log-index-logs-container {
        text-align: end;
    }
}
